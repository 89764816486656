/* Focus Areas Container */
.focus-container {
  text-align: center;
  padding: 50px 20px;
  background-color: #f8f9fa;
}

/* Heading */
.focus-container h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #333;
}

/* Responsive Grid Layout */
.focus-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

/* Individual Focus Box */
.focus-box {
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, filter 0.3s ease-in-out;
}

/* Overlay Effect */
.focus-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  padding: 20px;
  transition: background 0.3s ease-in-out;
  z-index: 2;
}

/* Hover Effect: Enhanced Colors & Shadows */
.focus-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  filter: brightness(1.3) saturate(1.2); /* Increases brightness & saturation */
}

.focus-box:hover .focus-overlay {
  background: rgba(0, 0, 0, 0.3); /* Makes background image more visible */
}

/* Gradient Overlay on Hover */
.focus-box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(255, 165, 0, 0.4), rgba(0, 0, 0, 0));
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}

.focus-box:hover::before {
  opacity: 1;
}

/* Text Styling */
.ijcc-text {
  font-size: 1.3rem;
  line-height: 1.8;
  color: #000000;
  padding: 10px 20px;
  text-align: justify;
  max-width: 900px;
  margin: 0 auto;
  font-weight: 400;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .focus-container {
    padding: 40px 10px;
  }

  .focus-box {
    height: 150px;
  }

  .focus-overlay {
    font-size: 1rem;
    padding: 15px;
  }

  .ijcc-text {
    font-size: 1.1rem;
  }
}