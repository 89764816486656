/* General Form Styling */
.membership-form {
    max-width: 800px;
    margin: 30px auto;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: Arial, sans-serif;
  }
  
  /* Heading Styles */
  .membership-form h2 {
    text-align: center;
    color: #003366;
    margin-bottom: 15px;
  }
  
  .membership-form h3 {
    color: #004080;
    margin-top: 20px;
    border-bottom: 2px solid #004080;
    padding-bottom: 5px;
  }
  
  /* Labels & Inputs */
  .membership-form label {
    display: block;
    font-weight: bold;
    margin-top: 12px;
    color: #333;
  }
  
  .membership-form input,
  .membership-form select,
  .membership-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    background: #f9f9f9;
  }
  
  /* Styling the Textarea */
  .membership-form textarea {
    height: 100px;
    resize: vertical;
  }
  
  /* File Upload */
  .membership-form input[type="file"] {
    border: none;
    background: none;
  }
  
  /* Submit Button */
  .membership-form button {
    width: 100%;
    padding: 12px;
    background: #003366;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
    transition: background 0.3s ease-in-out;
  }
  
  .membership-form button:hover {
    background: #002244;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .membership-form {
      width: 90%;
      padding: 15px;
    }
  
    .membership-form h2 {
      font-size: 22px;
    }
  
    .membership-form button {
      font-size: 16px;
      padding: 10px;
    }
  }