/* General Container */
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #930101;
  padding: 30px;
  font-family: "Arial", sans-serif;
}
/* Tabs Styling */
.tabs {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap; /* Prevents wrapping */
  padding-bottom: 5px;
  scrollbar-width: thin; /* Makes scrollbar thinner */
}

/* Hide scrollbar for Webkit browsers */
.tabs::-webkit-scrollbar {
  height: 5px;
}

.tabs::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.tabs::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Responsive: Allow Scrolling on Small Screens */
@media screen and (max-width: 768px) {
  .tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto; /* Enables horizontal scrolling */
    scroll-behavior: smooth; /* Smooth scrolling effect */
    -webkit-overflow-scrolling: touch;
  }

  .tabs button {
    flex: none; /* Prevents buttons from shrinking */
  }
}
/* Content Section */
.about-content {
  width: 70%;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Section Styling */
.section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

/* Text Content */
.section .text {
  width: 55%;
}

h2, h3 {
  color: #3889ae;
}

p {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

/* Image Styling */
.section-image {
  width: 40%;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

/* Focus Areas */
.focus-areas {
  display: flex;
  justify-content: space-between;
}

.focus-areas ul {
  list-style-type: none;
  padding: 0;
}

.focus-areas li {
  padding: 5px 0;
  font-weight: bold;
}

/* Sidebar */
.sidebar {
  width: 70%;
  background: #8b4513;
  color: white;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
}

.sidebar h4 {
  font-size: 18px;
  margin: 5px 0;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .section {
      flex-direction: column;
      text-align: center;
  }

  .section .text {
      width: 100%;
  }

  .section-image {
      width: 80%;
      margin-top: 15px;
  }
}
section {
  text-align: center;
  padding: 40px;
  background-color: #f9f9f9;
}

/* Image Gallery */
.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 180px;
  height: 230px;
}

.team-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.team-image:hover {
  transform: scale(1.1);
}

.team-name {
  margin-top: 10px;
  font-weight: bold;
  color: #333;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .about-content {
    width: 90%;
  }

  .team-member {
    width: 140px;
  }

  .team-image {
    width: 120px;
    height: 120px;
  }

  .team-name {
    font-size: 14px;
  }
}