/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Petrona:wght@300;400;600;700&display=swap');

/* Global Styles */
body {
  margin: 0;
  font-family: 'Petrona', serif; /* Elegant and stylish serif font */
  font-weight: 400;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa;
  line-height: 1.6;
}

/* Code Blocks */
code {
  font-family: 'Courier New', monospace; /* Monospace for coding elements */
  background: #eee;
  padding: 3px 6px;
  border-radius: 5px;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Petrona', serif;
  font-weight: 700;
  color: #222;
  margin-bottom: 10px;
}

/* Paragraphs */
p {
  font-size: 1rem;
  color: #444;
  line-height: 1.8;
  margin-bottom: 15px;
}

/* Links */
a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #0056b3;
}

/* Button Styling */
button {
  font-family: 'Petrona', serif;
  font-weight: 600;
  padding: 10px 20px;
  border: none;
  background: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s;
}

button:hover {
  background: #0056b3;
}