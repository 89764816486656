.hazy-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url("/public/images/Untitled design (15).png") center/cover no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-family: Arial, sans-serif;
}
.hazy-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: 1;
}

.hazy-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.1) 1px,
      transparent 1px
    ),
    linear-gradient(90deg, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  background-size: 10px 10px; /* Creates small box effect */
  opacity: 0.3;
  z-index: 2;
}
.title {
  position: relative;
  z-index: 3;
  text-align: center;
  color: #ffffff;
}

.title {
  font-size: 69px; /* Large title */
  font-weight: bold;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 1.2rem;
  max-width: 800px;
  line-height: 1.5;
  opacity: 0.9; /* Slight transparency for smooth blending */
}