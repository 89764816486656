.events-container {
    max-width: 900px;
    margin: auto;
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

/* Tabs */
.tabs {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

/* Content Section */
h2 {
    color: #333;
    font-size: 24px;
}

p {
    font-size: 16px;
    line-height: 1.6;
    color: #444;
}

.event {
    background: white;
    padding: 15px;
    margin: 10px 0;
    border-radius: 8px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.event h3 {
    color: #d10000;
}

.event p {
    margin: 5px 0;
}