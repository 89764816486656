.membership-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

/* Tabs */
.tabs {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.tabs button {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    background: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 5px;
}

.tabs button.active {
    background: #084781;
    color: #fff;
    border-color: #084781;
}

.tabs button:hover {
    background: #2c2891;
    color: #fff;
}

/* Membership Content */
.membership-content {
    text-align: left;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.membership-content h2 {
    color: #084781;
    font-size: 24px;
    margin-bottom: 15px;
}

.membership-content p,
.membership-content ul {
    font-size: 16px;
    line-height: 1.6;
    color: #444;
}

.membership-content ul {
    list-style: none;
    padding: 0;
}

.membership-content ul li {
    padding: 5px 0;
    font-weight: bold;
}

/* Image */
.membership-image {
    width: 100%;
    max-height: 250px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 15px;
}

/* Join Now Button */
.join-now {
    text-align: center;
    margin-top: 20px;
}

.join-now button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background: #084781;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.join-now button:hover {
    background: #2c2891;
}