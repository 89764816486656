/* Contact Section Container */
.contact-container {
    max-width: 600px;
    margin: 40px auto;
    padding: 30px;
    text-align: center;
    font-family: "Arial", sans-serif;
    background: linear-gradient(to bottom, #fffaf0, #f5f5f5);
    border-radius: 12px;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.contact-container:hover {
    transform: scale(1.03);
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
}

/* Heading */
.contact-container h2 {
    color: #333;
    font-size: 26px;
    margin-bottom: 15px;
}

/* Paragraph Styling */
.contact-container p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
}

/* Contact Details */
.contact-details {
    margin-bottom: 15px;
}

.contact-details p {
    font-size: 17px;
    font-weight: bold;
    color: #333;
}

/* Contact Form */
.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
    text-align: left;
}

/* Label Styling */
.contact-form label {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

/* Input and Textarea Styling */
.contact-form input,
.contact-form textarea {
    padding: 12px;
    border: 2px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    width: 100%;
    transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-color: #3889ae;
    outline: none;
    box-shadow: 0px 0px 10px rgba(56, 137, 174, 0.5);
}

/* Textarea Styling */
.contact-form textarea {
    resize: vertical;
    min-height: 120px;
}

/* Submit Button */
.contact-form button {
    padding: 12px 18px;
    border: none;
    background: #3889ae;
    color: white;
    cursor: pointer;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
    transition: background 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.contact-form button:hover {
    background: #2a6d89;
    transform: translateY(-2px);
}

/* Success Message */
.success-message {
    font-size: 18px;
    color: green;
    font-weight: bold;
    margin-top: 15px;
}

/* Responsive Design */

/* 📱 Mobile (small screens) */
@media (max-width: 576px) {
    .contact-container {
        max-width: 90%;
        padding: 20px;
    }

    .contact-container h2 {
        font-size: 22px;
    }

    .contact-container p {
        font-size: 14px;
    }

    .contact-form input,
    .contact-form textarea {
        font-size: 14px;
        padding: 10px;
    }

    .contact-form button {
        font-size: 16px;
        padding: 10px;
    }
}

/* 📲 Tablets (medium screens) */
@media (max-width: 768px) {
    .contact-container {
        max-width: 85%;
        padding: 22px;
    }

    .contact-container h2 {
        font-size: 24px;
    }

    .contact-form input,
    .contact-form textarea {
        font-size: 15px;
    }
}

/* 💻 Laptops (large screens) */
@media (max-width: 1024px) {
    .contact-container {
        max-width: 75%;
        padding: 25px;
    }

    .contact-form input,
    .contact-form textarea {
        font-size: 16px;
    }
}

/* 🖥️ Desktop (extra-large screens) */
@media (min-width: 1200px) {
    .contact-container {
        max-width: 600px;
    }

    .contact-form input,
    .contact-form textarea {
        font-size: 17px;
    }
}