.services-container {
    padding: 3rem 1rem;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .services-heading {
    font-size: 2.5rem;
    color: #084781;
    margin-bottom: 2rem;
    font-weight: bold;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    justify-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .service-card {
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 100%;
  }
  
  .service-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .service-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 1px solid #eaeaea;
  }
  
  .service-content {
    padding: 1.5rem;
    text-align: left;
  }
  
  .service-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 0.5rem;
  }
  
  .service-description {
    font-size: 1rem;
    color: #666666;
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .services-grid {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }