/* Navbar styling */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 15px 50px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;
  height: 80px;
}

/* Logo section */
.logo img {
  height: 180px;
}

/* Navigation Links */
.nav-links {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-links li {
  padding: 10px 15px;
}

.nav-link {
  text-decoration: none;
  font-weight: bold;
  color: black;
  transition: color 0.3s ease-in-out;
}

.nav-link:hover {
  color: #2974a2;
}

/* Active Link */
.nav-link.active {
  background-color: #3889ae;
  color: white;
  border-radius: 5px;
  padding: 10px 15px;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger span {
  background: black;
  width: 30px;
  height: 3px;
  margin: 5px 0;
  transition: 0.3s ease-in-out;
}

/* Open Hamburger Menu Animation */
.hamburger.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .navbar {
      padding: 15px 30px;
  }

  .nav-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 70px;
      left: 0;
      width: 100%;
      background: white;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      padding: 15px 0;
  }

  .nav-links.show {
      display: flex;
  }

  .nav-links li {
      text-align: center;
      padding: 15px 0;
      width: 100%;
  }

  .nav-link {
      display: block;
      width: 100%;
  }

  .hamburger {
      display: flex;
  }
}